import { deleteCookie, setCookie } from 'cookies-next';
import React, { createContext, useEffect, useState } from 'react';
import Grid from 'components/uiKit/grid';
import { SsrProps } from 'utils/globalTypes';
import { api } from 'utils/services/api';
import { track } from 'utils/functions/track';
import urls from 'utils/services/api/requests/urls';
import { useRouter } from 'next/router';
import { defaultAutoCloseTimer } from 'components/statusBanner/types';
import { gaAnalytics } from 'utils/functions/track/gaAnalytics';
import { AuthContextInterface } from './types';
const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
  saveNewToken: () => {},
  logout: () => {},
  token: ''
});
interface Props {
  children: React.ReactNode;
  pageProps: SsrProps;
}
interface externalGetAuthStatusInterface {
  isAuthenticated: boolean;
  token: string | undefined | null;
  logout: Function;
}
export const externalGetAuthStatus: externalGetAuthStatusInterface = {
  isAuthenticated: false,
  token: '',
  logout: () => {}
};
export function AuthProvider({
  children,
  pageProps
}: Props) {
  const router = useRouter();
  const {
    pathname
  } = router;
  const [isAuthenticated, setisAuthenticated] = useState(!!pageProps?.jwtToken);
  const [token, setToken] = useState(pageProps?.jwtToken);
  externalGetAuthStatus.isAuthenticated = isAuthenticated;
  externalGetAuthStatus.token = token;
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  useEffect(() => {
    if (!token && typeof window !== 'undefined') {
      const tokenLS = localStorage.getItem(process.env.NEXT_PUBLIC_API_JWT_NAME || '');
      if (tokenLS) {
        setToken(tokenLS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function saveNewToken(newToken: string) {
    setCookie(process.env.NEXT_PUBLIC_API_JWT_NAME || '', newToken, {
      maxAge: 30 * 24 * 60 * 60
    });
    setToken(newToken);
    api.defaults.headers.common.Authorization = `Bearer ${newToken}`;
    setisAuthenticated(true);
    externalGetAuthStatus.isAuthenticated = true;
    externalGetAuthStatus.token = `Bearer ${newToken}`;
    if (typeof window !== 'undefined') {
      localStorage.setItem(process.env.NEXT_PUBLIC_API_JWT_NAME || '', newToken);
    }
  }
  if (typeof window !== 'undefined') {
    (window as any).saveNewToken = saveNewToken;
  }
  async function logout() {
    if (isAuthenticated) {
      await fetch(urls.logout, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    deleteCookie(process.env.NEXT_PUBLIC_API_JWT_NAME || '');
    setToken(undefined);
    setisAuthenticated(false);
    track.onLogout();
    gaAnalytics.trackEvent({
      action: 'logout',
      category: 'logout'
    });
    api.defaults.headers.common.Authorization = '';
    externalGetAuthStatus.isAuthenticated = false;
    externalGetAuthStatus.token = '';
    if (typeof window !== 'undefined') {
      localStorage.removeItem(process.env.NEXT_PUBLIC_API_JWT_NAME || '');
    }
    router.replace({
      pathname: '/',
      query: {
        type: 'logout',
        autoClose: defaultAutoCloseTimer * 3
      }
    }, undefined, {
      shallow: true
    });
  }
  externalGetAuthStatus.logout = () => logout();
  return <AuthContext.Provider value={{
    isAuthenticated,
    saveNewToken,
    logout,
    token
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="index.tsx">
      {isAuthenticated ? <Grid key={token}>{children}</Grid> : <Grid key="logged-out">{children}</Grid>}
    </AuthContext.Provider>;
}
export default AuthContext;